// console.log('I have entered this file.');
// console.log('This is crystal clear evidence that this works.');

// const calculation = 200 + 223;

// console.log('Sanity Check: 200 + 223 = ' + calculation);
// console.log('bye bye Ika testing2.');


// FOOTER SLICK
jQuery(document).ready(function(){
	jQuery('.customer-logos').slick({
		slidesToShow: 6,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		arrows: false,
		dots: false,
		pauseOnHover: false,
		responsive: [
		{
			breakpoint: 2000,
			centerMode: true,
			settings: {
				slidesToShow: 4
			}
		},
		{
			breakpoint: 1500,
			centerMode: true,
			settings: {
				slidesToShow: 4
			}
		},
		{
			breakpoint: 992,
			centerMode: true,
			settings: {
				slidesToShow: 4
			}
		},
		
		{
			breakpoint: 768,
			centerMode: true,
			settings: {
				slidesToShow: 4
			}
		}, 
		{
			breakpoint: 520,
			settings: {
				slidesToShow: 3
			}
		},
		{
			breakpoint: 400,
			settings: {
				slidesToShow: 2
			}
		}]
	});
});

//----------- Fixed header when scrolling ------------ //

// When the user scrolls the page, execute scrollFunction 
window.onscroll = function() {scrollFunction()};

// Get the header
var header = document.getElementById("fixedtop");

// Get the offset position of the navbar
var sticky = header.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function scrollFunction() {
  if (window.pageYOffset > sticky) {
    header.classList.add("fixed-top");
  } else {
    header.classList.remove("fixed-top");
  }
}
//-------------------------------------------------- //







